<template>
   <div>
      <BaseLayout />
   </div>
</template>

<script setup lang="ts">
   import { usePusherStore } from '@/stores/pusher.pinia';

   const { setLocale, locale } = useI18n();
   if (locale.value !== 'en') {
      await setLocale('en');
      location.reload();
   }

   const { connectToPusher } = usePusherStore();
   onMounted(() => {
      connectToPusher(false);
   });
</script>
